import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ICBCUtil } from './utils/icbc_core';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
  Checkbox,
  CheckboxGroup,
  Uploader,
  Col,
  Row,
  Button,
  Field,
  CellGroup,
  Tab,
  Tabs,
  Tag,
  Stepper,
  Loading,
  Overlay,
  List,
  SubmitBar,
  Popup,
  Empty,
  Icon,
  Calendar,
  Cell,
  Tabbar,
  TabbarItem,
  Picker,
  Form,
  ActionSheet,
  DatetimePicker,
  Dialog,
  ImagePreview,
  Search,
  Swipe,
  SwipeItem,
  Popover,
  RadioGroup,
  Radio,
  SwipeCell
} from 'vant';
import CONSTANT from '@/constant/index';
import { deepClone } from '@/utils/util';
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.prototype.constant = CONSTANT;
Vue.prototype.$deepClone = deepClone;
Vue.config.productionTip = false;
Vue.prototype.$ICBCUtil = ICBCUtil;
Vue.use(ElementUI);
Vue.use(Uploader);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(SubmitBar);
Vue.use(Stepper);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(List);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Picker);
Vue.use(Form);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Search);
Vue.use(SwipeItem);
Vue.use(Swipe);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(SwipeCell);
// sessionStorage.setItem('userId', 132)

// router.beforeEach((to, from, next) => {
//   const userId = sessionStorage.getItem('userId')
//   if (to.name !== 'Home' && to.name !== 'Login') {
//     if (userId) {
//       if (!userId) {
//         store
//           .dispatch('user/getInfo', { userId })
//           .then(data => {
//             next()
//           })
//           .catch(err => {
//             console.log(err)
//           })
//       } else {
//         next()
//       }
//     } else {
//       next({ name: 'Login' })
//     }
//   } else {
//     next()
//   }
// })

// const personInfo = JSON.parse(sessionStorage.getItem('personInfo'))
// // const blocked = personInfo.blocked
// console.log(11111111, personInfo)
// router.beforeEach((to, from, next) => {
//   console.log(to, from)
//   if (to.name === 'Home') {
//     console.log(to.name)
//     next()
//   } else {
//     next()
//   }
// })
// router.beforeEach((to, from, next) => {
//   if (to.name === 'index') {
//     if (blocked === 1) {
//       console.log('账户冻结')
//       return
//     } else if (blocked === 0) {
//       next()
//     }
//   } else {
//     next()
//   }
//   // if (to.name !== 'index') {
//   //   console.log(666)
//   // } else {
//   //   next()
//   // }
//   // const blocked = sessionStorage.getItem('personInfo').blocked
//   // console.log(22222222222222222, blocked)
// })

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
