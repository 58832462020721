import moment from 'moment';

export const toDate = (time) => {
  return moment(time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss');
};

export const timeToDate = (time) => {
  return moment(+time).format('YYYY-MM-DD HH:mm:ss');
};

export const getMoney = (money) => {
  return parseFloat(money).toFixed(2);
};

/**
 * 格式化 vue 数据，用于 console.log，去除...
 * @param data
 * @returns {any}
 */
export const f = (data) => {
  return JSON.parse(JSON.stringify(data));
};

/**
 * 过滤空属性
 * @param params
 * @returns {{}|*}
 */
export const filter = (params) => {
  try {
    if (params instanceof FormData) return params;
    if (typeof params === 'object') {
      const data = {};
      for (const key in params) {
        if (params[key] === undefined) continue;
        if (params[key] === null) continue;
        if (params[key] === '') continue;
        data[key] = params[key];
      }
      return data;
    }
    return params;
  } catch (e) {
    return params;
  }
};

export const currencyTransform = (fen) => {
  return currency((fen / 100).toFixed(2) + '', null, 2);
};

const digitsRE = /(\d{3})(?=\d)/g;

/**
 * value  金额
 * currency 货币符号
 * decimals  保留位数
 */
export const currency = (value, currency, decimals) => {
  value = parseFloat(value);
  if (!isFinite(value) || (!value && value !== 0)) return '';
  currency = currency != null ? currency : '¥';
  decimals = decimals != null ? decimals : 2;
  const stringified = Math.abs(value).toFixed(decimals);
  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? ',' : '') : '';
  const _float = decimals ? stringified.slice(-1 - decimals) : '';
  const sign = value < 0 ? '-' : '';
  return (
    sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
  );
};

// 深拷贝
/**
 * 深拷贝方法
 * @param {*} obj - 需要拷贝的对象
 * @param {WeakMap} cache - 缓存对象，用于解决循环引用问题
 * @returns {*} 深拷贝后的对象
 * @throws {Error} 当遇到不支持的类型时抛出错误
 */
export const deepClone = (obj, cache = new WeakMap()) => {
  // 基本类型直接返回
  if (
    obj === null ||
    typeof obj !== 'object' ||
    Object.prototype.toString.call(obj) !== '[object Object]'
  ) {
    return obj;
  }

  // 检查缓存，避免循环引用
  if (cache.has(obj)) {
    return cache.get(obj);
  }

  // 使用 Object.prototype.toString.call 获取精确类型
  const type = Object.prototype.toString.call(obj);

  switch (type) {
    // 处理 Date 对象
    case '[object Date]': {
      const copy = new Date(obj);
      cache.set(obj, copy);
      return copy;
    }

    // 处理 RegExp 对象
    case '[object RegExp]': {
      const copy = new RegExp(obj);
      cache.set(obj, copy);
      return copy;
    }

    // 处理 Map 对象
    case '[object Map]': {
      const copy = new Map();
      cache.set(obj, copy);
      for (const [key, value] of obj) {
        copy.set(deepClone(key, cache), deepClone(value, cache));
      }
      return copy;
    }

    // 处理 Set 对象
    case '[object Set]': {
      const copy = new Set();
      cache.set(obj, copy);
      for (const value of obj) {
        copy.add(deepClone(value, cache));
      }
      return copy;
    }

    // 处理 Array 对象
    case '[object Array]': {
      const copy = [];
      cache.set(obj, copy);
      for (const item of obj) {
        copy.push(deepClone(item, cache));
      }
      return copy;
    }

    // 处理普通对象
    case '[object Object]': {
      const copy = {};
      cache.set(obj, copy);
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          copy[key] = deepClone(obj[key], cache);
        }
      }
      // 处理 Symbol 类型的属性
      const symbolKeys = Object.getOwnPropertySymbols(obj);
      for (const symbolKey of symbolKeys) {
        copy[symbolKey] = deepClone(obj[symbolKey], cache);
      }
      return copy;
    }

    // 不支持的类型抛出错误
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
